<script>
import { defineComponent, computed, reactive, ref, onMounted } from "vue";
import clickaway from "@/directives/clickaway";
import ObjectUtils from "../utils/ObjectUtils";

let uuid = 0;

export default defineComponent({
  name: "BaseSelect",
  directives: {
    clickaway
  },
  props: {
    name: {
      type: String,
      default: "Select"
    },
    value: {
      type: [Object, Array, String],
      default: null
    },
    modelValue: {
      type: Object,
      default: null
    },
    field: {
      type: String,
      default: null
    },
    options: {
      type: [Array, Object],
      default: () => []
    },
    selectClasses: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  emits: ["input", "update:modelValue"],
  setup(props, { emit }) {
    const id = "select_" + uuid;

    uuid += 1;

    const selected = ref(null);
    const state = reactive({
      expanded: false
    })

    onMounted(() => {
      if(props.modelValue) {
        selected.value = props.modelValue
      }
    })

    const buttonClasses = computed(() => {
      return [
        "inline-flex items-center justify-between w-full py-2 px-3 font-sans text-sm font-normal leading-10 transition duration-150 ease-in-out cursor-pointer bg-inherit text-default focus:outline-none focus:shadow-outline",
        props.selectClasses !== ""
          ? props.selectClasses
          : "border border-gray-300 border-solid shadow-none rounded-md"
      ];
    });

    function isOptionSelected(option) {
      return ObjectUtils.equals(selected.value, option);
    }

    function onSelect(option) {
      state.expanded = false;
      selected.value = option
      emit("input", option)
      emit("update:modelValue", option);
    }

    function onTriggerClick() {
      state.expanded = !state.expanded;
    }
    function onClickaway() {
      state.expanded = false;
    }

    function getItemContent(item) {
      return props.field
        ? ObjectUtils.resolveFieldData(item, props.field)
        : item;
    }

    return {
      isOptionSelected,
      onSelect,
      onTriggerClick,
      onClickaway,
      expanded: computed(() => state.expanded),
      getItemContent,
      selected,
      id,
      buttonClasses
    };
  }
});
</script>

<template>
  <div class="relative text-left">
    <label
      v-if="label"
      :for="id"
      class="block mb-1 text-sm font-medium leading-5 text-gray-700"
    >
      <span>{{ label }}</span>
      <span
        v-if="required"
        class="text-base text-red-500"
      >
        *
      </span>
    </label>
    <div>
      <button
        :id="id"
        type="button"
        :class="buttonClasses"
        aria-haspopup="true"
        aria-expanded="true"
        @click.capture="onTriggerClick"
      >
        <slot
          v-if="selected"
          :option="selected"
          name="optionSelected"
        >
          <span>{{ getItemContent(selected) }}</span>
        </slot>
        <svg
          class="w-5 h-5 ml-2 -mr-1 text-light"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path
            d="M0 0h24v24H0z"
            fill="none"
          />
          <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
        </svg>
      </button>
    </div>
    <transition name="slide-y">
      <div
        v-if="expanded"
        v-clickaway="onClickaway"
        class="absolute right-0 w-full z-50 bg-white origin-top-right rounded-md shadow-lg min-w-56 z-popup"
      >
        <div class="rounded-md shadow-xs bg-light">
          <div
            class="py-2"
            role="menu"
            aria-orientation="vertical"
            :aria-labelledby="id"
          >
            <div
              v-for="(option, key) in options"
              :key="key"
              :class="{ 'text-primary': isOptionSelected(option) }"
              class="block px-4 py-2 cursor-pointer focus:outline-none hover:bg-primary-lightest"
              role="menuitem"
              @click="onSelect(option)"
            >
              <slot
                :option="option"
                name="option"
              >
                {{ getItemContent(option) }}
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
