// auth.js
import { defineAsyncComponent  } from 'vue'
import guest from '@/middleware/guest'

// Components
const AuthContainer = defineAsyncComponent(
    () => import("@/views/Auth/AuthContainer.vue")
);
const Login = defineAsyncComponent(
    () => import("@/views/Auth/Login.vue")
);
const Recovery = defineAsyncComponent(
    () => import("@/views/Auth/Recovery.vue")
);

export default [
  {
    path: '/auth',
    name: 'Auth',
    component: AuthContainer,
    meta: {
      middleware: [guest],
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login,
        meta: {
          middleware: [guest],
        }
      },
      {
        path: 'recovery',
        name: 'Recovery',
        component: Recovery,
        meta: {
          middleware: [guest],
        },
      },
    ],
  },
]