import { reactive } from 'vue'

const state = reactive({
  basePath: '/icons',
  icons: {},
})

export function setBasePath(basePath) {
  state.basePath = basePath
}

async function getIcon(name) {
  const { icons, basePath } = state

  if (!name) return null
  if (icons[name]) return icons[name]

  const iconPath = `${basePath}/${name}.svg`
  const response = await fetch(iconPath)

  if (response.ok) {
    const result = await response.text()

    if (/^<svg/.test(result)) {
      icons[name] = result
    } else {
      console.warn(`Invalid icon content loaded from ${iconPath}`)
    }

    return result
  }
}

export function useIcons() {
  return {
    getIcon,
  }
}

export function setIcons(preset) {
  const { prefix } = preset
  const source = preset.icons || preset

  Object.keys(source).forEach(key => {
    const name = prefix ? `${prefix}-${key}` : key

    if (state.icons[name]) {
      console.warn(`"${name}" already exists. Please, make sure that names are unique.`)
    }

    state.icons[name] = source[key]
  })
}
