import { defineAsyncComponent } from "vue";
import { createWebHistory, createRouter } from "vue-router";

import routesAuth from "./auth";
import routesProfile from "./profile";
import routesProject from "./project";
import routesTeam from "./team";
import auth from "../middleware/auth";

// Dashboard
const Dashboard = defineAsyncComponent(() => import("@/views/Dashboard.vue"));
const DashboardIndex = defineAsyncComponent(() =>
  import("@/views/Dashboard/DashboardIndex.vue")
);
const Error404 = defineAsyncComponent(() => import("@/views/errors/404.vue"));

const ServiceRoute = defineAsyncComponent(() =>
  import("@/services/router/index.js")
);

const routes = [
  {
    path: "/",
    redirect: {name: 'Login'}
  },
  {
    path: "/dashboard",
    component: Dashboard,
    meta: {
      middleware: [auth]
    },
    children: [
      {
        path: "",
        name: "DashboardIndex",
        component: DashboardIndex,
        meta: {
          middleware: [auth]
        }
      },
      {
        path: "email/verify/:id/:hash",
        name: "email.verify",
        component: DashboardIndex,
        meta: {
          middleware: [auth]
        }
      },
      ...routesProfile,
      ...routesProject,
      ...routesTeam
    ]
  },
  ...routesAuth,
  {
    path: "/service/:service",
    name: "Service",
    component: ServiceRoute
  },
  {
    path: "/:catchAll(.*)",
    name: "Error404",
    component: Error404
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes, // @ts-ignore
  linkActiveClass: "side-menu--active"
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);

    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  } else {
    return next();
  }
});

export default router;
