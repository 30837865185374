import * as api from "@/plugins/api";

const actions = {
  async getTypes() {
    return await api.fetch({
      url: '/projects/types',
      resource: "v1"
    });
  }
};

export default {
  namespaced: true,
  actions
};
