const ID_API_TOKEN = 'jwt-token';
const ID_UER_ROLES = 'user-roles';

export const getToken = () => {
    return window.localStorage.getItem(ID_API_TOKEN)
};

export const setToken = token => {
    window.localStorage.setItem(ID_API_TOKEN, token);
};

export const unsetToken = () => {
    window.localStorage.removeItem(ID_API_TOKEN);
};

export const setRoles = roles => {
    window.localStorage.setItem(ID_UER_ROLES, JSON.stringify(roles));
};

export const getRoles = () => {
    return JSON.parse(window.localStorage.getItem(ID_UER_ROLES))
};

export const unsetRoles = () => {
    window.localStorage.removeItem(ID_UER_ROLES);
};

export default {getToken, setToken, unsetToken, setRoles, unsetRoles, getRoles}