import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/sass/app.scss'
import store from './store'
import api from './plugins/api'
import iconPlugin from './plugins/icon'
import JwtService from "./services/jwt.service";

//Components
import Button from './components/button/Button'
import Select from './components/select/base-select'
import Ripple from './components/ripple/Ripple'
import ToastService from './components/toast/ToastService';

let app = createApp(App).use(store).use(store).use(router);

app.component('BaseButton', Button)
app.component('BaseSelect', Select)
app.directive('ripple', Ripple);
app.use(ToastService);
app.use(iconPlugin)
app.use(api, {
    default: 'v1',
    resources: [
        {
            name: 'v1',
            baseUrl: process.env.VUE_APP_API_URL,
            async headers() {
                const token = await JwtService.getToken()

                return {
                    'Authorization': `Bearer ${token}`,
                };
            },
        },
    ],
})

app.directive('click-outside', {
    priority: 700,
    bind () {
        let self  = this
        this.event = function (event) {
            console.log('emitting event')
            self.vm.$emit(self.expression,event)
        }
        this.el.addEventListener('click', this.stopProp)
        document.body.addEventListener('click',this.event)
    },

    unbind() {
        console.log('unbind')
        this.el.removeEventListener('click', this.stopProp)
        document.body.removeEventListener('click',this.event)
    },
    stopProp(event) {event.stopPropagation() }
})

app.mount('#app');
