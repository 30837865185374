import * as api from "@/plugins/api";

const state = {
  teams: [],
  team: {}
};

const getters = {
  teamList(state) {
    return state.teams;
  },
  team({ team }) {
    return team;
  }
};

const mutations = {
  setTeamList(state, payload) {
    state.teams = payload;
  },
  setTeam(state, payload) {
    state.team = payload;
  }
};

const actions = {
  async getTeamList({ commit },query) {
    try {
      const { data } = await api.fetch({
        url: `/teams?filter[name]=${query}`,
        resource: "v1"
      });
      return data;
    } catch (e) {
      commit("setTeamList", []);
      throw e;
    }
  },
  async storeTeam(_, body) {
    try {
      return await api.fetch({
        url: "/teams",
        resource: "v1",
        method: "POST",
        body
      });
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getTeam({ commit }, id) {
    try {
      const response = await api.fetch({
        url: `/teams/${id}`,
        resource: "v1"
      });
      commit("setTeam", response.data);
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async updateTeam(_, payload) {
    try {
      const response = await api.fetch({
        url: `/teams/${payload.id}`,
        resource: "v1",
        method: "PUT",
        body: payload
      });
      // commit("setTeam", response.data);
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async updateLogo(_, {id, body}) {
    try {
      return await api.fetch({
        url: `/teams/${id}/logo`,
        resource: 'v1',
        method: 'POST',
        contentType: 'multipart/form-data',
        body
      })
    }catch (e) {
      console.log(e);
      throw e;
    }
  },
  async deleteTeam(_, id) {
    try {
      const response = await api.fetch({
        url: `/teams/${id}`,
        resource: "v1",
        method: "DELETE",
      });
      // commit("setTeam", response.data);
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getMembers(_, {id, filterQuery}) {
    try {
      return await api.fetch({
        url: `/teams/members/${id}?filter[q]=${filterQuery}`,
        resource: "v1"
      });
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getInvites(_, { teamId, page }) {
    try {
      return await api.fetch({
        url: `/teams/invite/${teamId}?page=${page}`,
        resource: "v1"
      });
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async declineInvite(_, { teamId, deny_token }) {
    try {
      return await api.fetch({
        url: `/teams/invite/${teamId}/${deny_token}/decline`,
        resource: "v1",
        method: "DELETE"
      });
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async employeeSendInvite(_, { teamId, body }) {
    try {
      return await api.fetch({
        url: `/teams/invite/${teamId}`,
        resource: "v1",
        method: "POST",
        body
      });
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async employeeSendInviteCreate(_, { teamId, body }) {
    try {
      return await api.fetch({
        url: `/teams/invite/${teamId}/create-user`,
        resource: "v1",
        method: "POST",
        body
      });
    } catch (e) {
      console.log(e);
      throw e;
    }
  }
};

export default {
  namespaced: true,
  getters,
  actions,
  mutations,
  state
};
