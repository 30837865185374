import * as api from "@/plugins/api";

const actions = {
  async getStatuses() {
    return await api.fetch({
      url: '/projects/status',
      resource: "v1"
    });
  }
};

export default {
  namespaced: true,
  actions
};
