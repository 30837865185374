import * as api from '@/plugins/api'

const state = {
    notifications: [],
    currentUser: {
        position: {}
    }
};

const getters = {
    currentUser(state) {
        return state.currentUser;
    },
    notifications(state) {
        return state.notifications;
    }
};

const mutations = {
    setCurrentUser(state, payload) {
        state.currentUser = payload;
    },
    setProfilePhoto(state, payload) {
        state.currentUser.profile_photo = payload.photo;
    },
    setNotifications(state, payload) {
        state.notifications = payload;
    }
};

const actions = {
    async getAuthUser({commit}) {
        try {
            const response =   await api.fetch({
                url: '/user',
                resource: 'v1',
            })
            commit('setCurrentUser', response.data);
            return response;
        }catch (e) {
            console.log(e);
        }
    },
    async emailVerify(_, params) {
        try {
            return await api.fetch({
                url: `/email/verify/${params.id}/${params.hash}?expires=${params.expires}&signature=${params.signature}`,
                resource: 'v1'
            });
        }catch (e) {
            console.log(e);
            throw e
        }
    },
    async updateDisplayInformation(_, payload) {
        try {
            return await api.fetch({
                url: '/user/update/display-information',
                resource: 'v1',
                method: 'PATCH',
                body: payload
            })
        }catch (e) {
            console.log(e);
            throw e;
        }
    },
    async updatePersonalInformation(_, payload) {
        try {
            return await api.fetch({
                url: '/user/update/personal-information',
                resource: 'v1',
                method: 'PATCH',
                body: payload
            })
        }catch (e) {
            console.log(e);
            throw e;
        }
    },
    async updateProfilePhoto({ commit }, payload) {
        try {
            const data = await api.fetch({
                url: '/user/update/profile-photo',
                resource: 'v1',
                method: 'POST',
                contentType: 'multipart/form-data',
                body: payload
            })
            commit('setProfilePhoto', data)
        }catch (e) {
            console.log(e);
            throw e;
        }
    },
    async updatePassword(_, payload) {
        try {
            return await api.fetch({
                url: '/user/update/password',
                resource: 'v1',
                method: 'PATCH',
                body: payload
            })
        }catch (e) {
            console.log(e);
            throw e
        }
    },
    async getNotifications({commit}) {
        try {
            const {data} =  await api.fetch({
                url: '/user/notifications',
                resource: 'v1',
            })
            commit('setNotifications', data);
        }catch (e) {
            console.log(e);
            throw e
        }
    },
};

export default {
    namespaced: true,
    getters,
    actions,
    mutations,
    state
}
