<template>
  <button
    v-ripple
    :class="buttonClass"
    type="button"
    :disabled="loading || disabled"
  >
    <div
      v-if="loading"
      class="ri-restart-line animate-spin"
    />
    <template v-else>
      <i
        v-if="icon"
        :class="iconClass"
      />
      <slot>{{ label }}</slot>
    </template>
  </button>
</template>

<script>
import Ripple from '../ripple/Ripple';

export default {
    directives: {
        'ripple': Ripple
    },
    props: {
        label: {
            type: String,
            default: '',
        },
        icon: {
            type: String
        },
        iconPos: {
            type: String,
            default: 'left'
        },
        badge: {
            type: String
        },
        badgeClass: {
            type: String,
            default: null
        },
        variant: {
            type: String,
            default: 'bg-theme-1'
        },
        size: {
            type: String,
            default: ''
        },
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        buttonClass() {
            const variant = !this.loading ? this.variant : ''
            return [
               variant,
                this.size,
                {
                'button flex justify-center text-white relative': true,
                'bg-gray-500 text-gray-300': this.loading,
                'disabled': this.$attrs.disabled || this.loading,
            }]
        },
        iconClass() {
            return [
                this.icon
            ]
        }
    }
}
</script>
