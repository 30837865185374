import JwtService from "@/services/jwt.service.js"
import * as api from '@/plugins/api'

const state = {
    user: {}
};

const getters = {
    currentUser(state) {
        return state.user;
    },
};

const mutations = {
    setToken(state, data) {
        JwtService.setToken(data.access_token);
    },
    clearUser() {
        JwtService.unsetToken();
    },
    setCurrentUser(state, data) {
        state.user = data
    },
    logout() {
        JwtService.unsetRoles();
        JwtService.unsetToken();
    },
};

const actions = {
    async login({commit}, credentials) {
        try {
            const response = await api.fetch({
                url: '/login',
                resource: 'v1',
                method: 'POST',
                body: credentials
            });
            commit("setToken", response);
        } catch (e) {
            console.log(e);
            commit("clearUser");
            throw e
        }
    },
    async logout({commit}) {
        try {
            await api.fetch({
                url: '/user/logout',
                resource: 'v1',
                method: 'GET',
            });
            commit("logout");
        } catch (e) {
            commit("logout");
            throw e
        }
    },
    async register({commit}, {form, params = null}) {
        try {
            return await api.fetch({
                url: `/auth/register`,
                resource: 'v1',
                method: 'POST',
                body: {
                    ...form,
                    params
                }
            })
        } catch (e) {
            commit("clearUser");
            throw e;
        }
    }
};

export default {
    namespaced: true,
    getters,
    actions,
    mutations,
    state
}
