// profile.js
import auth from '@/middleware/auth'
import {defineAsyncComponent} from "vue";

// Profile
const ProfileContainer = defineAsyncComponent(
    () => import("@/views/Dashboard/Profile/ProfileContainer.vue")
);

const ProfileIndex = defineAsyncComponent(
    () => import("@/views/Dashboard/Profile/ProfileIndex.vue")
);

const SettingsContainer = defineAsyncComponent(
    () => import("@/views/Dashboard/Profile/Settings/SettingsContainer.vue")
);

const SettingsPersonalInformation = defineAsyncComponent(
    () => import("@/views/Dashboard/Profile/Settings/SettingsPersonalInformation.vue")
);

const SettingsAccount = defineAsyncComponent(
    () => import("@/views/Dashboard/Profile/Settings/SettingsAccount.vue")
);

const SettingsChangePassword = defineAsyncComponent(
    () => import("@/views/Dashboard/Profile/Settings/SettingsChangePassword.vue")
);

export default [
  {
    path: 'profile',
    component: ProfileContainer,
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: '',
        name: 'dashboard.profile.index',
        component: ProfileIndex,
        meta: {
          middleware: [auth]
        }
      },
      {
        path: 'settings',
        component: SettingsContainer,
        meta: {
          middleware: [auth]
        },
        children: [
          {
              path: '',
              name: 'dashboard.profile.settings',
              redirect: {name: 'dashboard.profile.settings.personal-information'}
          },
          {
            path: 'personal',
            name: 'dashboard.profile.settings.personal-information',
            component: SettingsPersonalInformation,
            meta: {
              middleware: [auth]
            }
          },
          {
            path: 'account',
            name: 'dashboard.profile.settings.account',
            component: SettingsAccount,
            meta: {
              middleware: [auth]
            }
          },
          {
            path: 'change-password',
            name: 'dashboard.profile.settings.change-password',
            component: SettingsChangePassword,
            meta: {
              middleware: [auth]
            }
          }
        ]
      }
    ]
  }
]