import * as api from '@/plugins/api'

const state = {
    skills: []
};

const getters = {
    skillList(state) {
        return state.skills;
    },
};

const mutations = {
    setSkillList(state, payload) {
        state.skills =payload;
    }
};

const actions = {
    async getSkillList({commit}, name) {
        const { data } = await api.fetch({
            url: `/skills?filter[name]=${name}`,
            resource: 'v1',
        })
        commit("setSkillList", data)
        return data;
    },
};

export default {
    namespaced: true,
    getters,
    actions,
    mutations,
    state
}
