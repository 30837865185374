import { setIcons, setBasePath } from './core/provider'
import BaseIcon from './components/base-icon.vue'

const plugin = function(app, options = {}) {
  app.component('BaseIcon', BaseIcon)

  if (options.basePath) {
    setBasePath(options.basePath)
  }

  if (options.presets instanceof Array) {
    options.presets.forEach(preset => {
      setIcons(preset)
    })
  }
}

export default plugin
export * from './core/provider'
