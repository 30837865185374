import * as api from "@/plugins/api";

const actions = {
  async search(_, query) {
    return await api.fetch({
      url: `/search?filter[q]=${query}`,
      resource: "v1"
    });
  }
};

export default {
  namespaced: true,
  actions,
};
