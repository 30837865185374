import { createStore } from 'vuex'
import auth from './modules/auth';
import profile from './modules/profile';
import team from './modules/team';
import project from './modules/project';
import projectType from './modules/project/type';
import projectStatus from './modules/project/status';
import skill from './modules/skill';
import search from './modules/search';

export default createStore({
  state: {
  },
  mutations: {
  },
  getters: {
  },
  actions: {
  },
  modules: {
    auth,
    profile,
    team,
    project,
    projectType,
    projectStatus,
    skill,
    search
  }
})
