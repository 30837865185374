// project.js
import auth from '@/middleware/auth'
import {defineAsyncComponent} from "vue";


const ProjectContainer = defineAsyncComponent(
    () => import("@/views/Dashboard/Project/ProjectContainer.vue")
);
const ProjectIndex = defineAsyncComponent(
    () => import("@/views/Dashboard/Project/ProjectIndex.vue")
);
const ProjectShow = defineAsyncComponent(
    () => import("@/views/Dashboard/Project/ProjectShow.vue")
);
const ProjectShowOverview = defineAsyncComponent(
    () => import("@/views/Dashboard/Project/ProjectShowOverview.vue")
);
const ProjectShowTeams = defineAsyncComponent(
    () => import("@/views/Dashboard/Project/ProjectShowTeams.vue")
);
const ProjectCreate = defineAsyncComponent(
    () => import("@/views/Dashboard/Project/ProjectCreate.vue")
);
const ProjectEdit = defineAsyncComponent(
    () => import("@/views/Dashboard/Project/ProjectEdit.vue")
);

export default [
      {
        path: 'projects',
        component: ProjectContainer,
        meta: {
          middleware: [auth],
          role: 'client'
        },
        children: [
          {
            path: '',
            name: 'dashboard.client.project.index',
            component: ProjectIndex,
            meta: {
              middleware: [auth],
              role: 'client'
            },
          },
          {
            path: ':id',
            component: ProjectShow,
            meta: {
              middleware: [auth],
              role: 'client'
            },
            children: [
              {
                path: '',
                name: 'dashboard.client.project.show',
                redirect: {name: 'dashboard.client.project.show.overview'}
              },
              {
                path: 'overview',
                name: 'dashboard.client.project.show.overview',
                component: ProjectShowOverview,
                meta: {
                  middleware: [auth],
                  role: 'client'
                },
              },
              {
                path: 'teams',
                name: 'dashboard.client.project.show.teams',
                component: ProjectShowTeams,
                meta: {
                  middleware: [auth],
                  role: 'client'
                },
              },
            ]
          },
          {
            path: 'create',
            name: 'dashboard.client.project.create',
            component: ProjectCreate,
            meta: {
              middleware: [auth],
              role: 'client'
            },
          },
          {
            path: ':id/edit',
            name: 'dashboard.client.project.edit',
            component: ProjectEdit,
            meta: {
              middleware: [auth],
              role: 'client'
            },
          },
        ]
  }
]