import * as api from "@/plugins/api";

const state = {
  project: {},
  projectList: []
};

const getters = {
  project(state) {
    return state.project;
  },
  projectList(state) {
    return state.projectList;
  }
};

const mutations = {
  setProject(state, payload) {
    state.project = payload;
  },
  setProjectList(state, payload) {
    state.projectList = payload;
  },
  deleteProjectFromList(state, id) {
    let index = state.projectList.findIndex(p => p.id === id);
    if (index !== -1) {
      state.projectList.splice(index, 1);
    }
  }
};

const actions = {
  async getProject({ commit }, id) {
    try {
      const response = await api.fetch({
        url: `/projects/${id}`,
        resource: "v1"
      });
      console.log('AA', response.data);
      commit("setProject", response.data);
      return response;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getProjectList(
    context,
    {
      page = 1,
      filters = { query: "", type: { key: "" }, status: { key: "" } }
    }
  ) {
    const response = await api.fetch({
      url: `/projects/?page=${page}&filter[query]=${filters.query}&filter[type]=${filters.type.key}&filter[status]=${filters.status.key}`,
      resource: "v1"
    });
    context.commit("setProjectList", response.data);
    return response;
  },
  async getTeams(context, { id, page = 1, filters = { query: "" } }) {
    return await api.fetch({
      url: `/projects/${id}/teams/?page=${page}&filter[query]=${filters.query}`,
      resource: "v1"
    });
  },
  async projectStore(context, payload) {
    try {
      return await api.fetch({
        url: `/projects`,
        resource: "v1",
        method: "POST",
        body: payload
      });
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async projectUpdate(context, payload) {
    return await api.fetch({
      url: `/projects/${payload.id}`,
      resource: "v1",
      method: "PATCH",
      body: payload
    });
  },
  async projectAttachmentUpload(context, { id, attachments }) {
    return await api.fetch({
      url: `/projects/${id}/attachments`,
      resource: "v1",
      method: "POST",
      contentType: "multipart/form-data",
      body: attachments
    });
  },
  async projectAttachmentDelete(context, { id, mediaId }) {
    return await api.fetch({
      url: `/projects/${id}/attachments/${mediaId}`,
      resource: "v1",
      method: "DELETE"
    });
  },
  async projectDelete(context, id) {
    let response = await api.fetch({
      url: `/projects/${id}`,
      resource: "v1",
      method: "DELETE"
    });

    context.commit("deleteProjectFromList", id);

    return response;
  }
};

export default {
  namespaced: true,
  getters,
  actions,
  mutations,
  state
};
