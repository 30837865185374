<script lang="ts">
import { defineComponent } from 'vue'
import { useIcons } from '../core/provider'

export default defineComponent({
  name: 'BaseIcon',
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'md',
      validator: v => !v || /xs|sm|sme|md|lg|xl/.test(v),
    },
  },
  setup() {
    const { getIcon } = useIcons()

    return {
      getIcon,
    }
  },
  data() {
    return {
      svg: '',
    }
  },
  watch: {
    name() {
      this.loadIcon()
    },
  },
  created() {
    this.loadIcon()
  },
  methods: {
    async loadIcon() {
      const svg = await this.getIcon(this.name)

      if (svg) {
        this.svg = svg
        await this.$nextTick()

        const el = this.$el.querySelector('svg')

        if (el) {
          el.classList.add('block', 'w-full', 'h-full')
          el.removeAttribute('height')
          el.removeAttribute('width')
        }
      }
    },
  },
})
</script>

<template>
  <div
    v-if="svg"
    class="flex-shrink-0"
    :data-icon="name"
    :class="[
      size === 'xs' && 'w-4 h-4',
      size === 'sm' && 'w-5 h-5',
      size === 'sme' && 'w-6 h-6 p-px',
      size === 'md' && 'w-6 h-6',
      size === 'lg' && 'w-8 h-8 p-px',
      size === 'xl' && 'w-8 h-8',
    ]"
    v-html="svg"
  />
</template>
