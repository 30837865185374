// team.js
import auth from "@/middleware/auth";
import { defineAsyncComponent } from "vue";

// Team
const TeamContainer = defineAsyncComponent(() =>
  import("@/views/Dashboard/Team/TeamContainer.vue")
);

const TeamIndex = defineAsyncComponent(() =>
  import("@/views/Dashboard/Team/TeamIndex.vue")
);

const TeamCreate = defineAsyncComponent(() =>
  import("@/views/Dashboard/Team/TeamCreate.vue")
);

const TeamShowContainer = defineAsyncComponent(() =>
  import("@/views/Dashboard/Team/TeamShowContainer.vue")
);

const TeamMembers = defineAsyncComponent(() =>
  import("@/views/Dashboard/Team/TeamMembers.vue")
);

const TeamInvites = defineAsyncComponent(() =>
  import("@/views/Dashboard/Team/Invite/TeamInvites.vue")
);

const TeamInvite = defineAsyncComponent(() =>
  import("@/views/Dashboard/Team/Invite/TeamInvite.vue")
);

const ManagementSettings = defineAsyncComponent(() =>
  import("@/views/Dashboard/Team/Management/ManagementSettings.vue")
);

export default [
  {
    path: "team",
    component: TeamContainer,
    meta: {
      middleware: [auth]
    },
    children: [
      {
        path: "",
        name: "dashboard.team.index",
        component: TeamIndex,
        meta: {
          middleware: [auth]
        }
      },
      {
        path: "create",
        name: "dashboard.team.create",
        component: TeamCreate,
        meta: {
          middleware: [auth]
        }
      },
      {
        path: ":id",
        component: TeamShowContainer,
        meta: {
          middleware: [auth]
        },
        children: [
          { path: "", name: 'dashboard.team.show', redirect: { name: "dashboard.team.members" } },
          {
            path: "members",
            name: "dashboard.team.members",
            component: TeamMembers,
            meta: {
              middleware: [auth]
            }
          },
          {
            path: "invites",
            name: "dashboard.team.invites",
            component: TeamInvites,
            meta: {
              middleware: [auth]
            }
          },
          {
            path: "member/invite",
            name: "dashboard.team.member.invite",
            component: TeamInvite,
            meta: {
              middleware: [auth]
            }
          },
          {
            path: "settings",
            name: "dashboard.team.management.settings",
            component: ManagementSettings,
            meta: {
              middleware: [auth]
            }
          }
        ]
      }
    ]
  }
];
